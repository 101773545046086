import { Alert, Box, Chip, FormGroup } from '@mui/material';
import { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { pick } from '@sbiz/util-common';

import {
  getFields,
  FieldName,
  FieldProps,
  FormAlertSeverity,
  FormPart,
  FormProps,
  FormSwitchProps,
  getInitialFormData,
  getError,
  getFormSubmittedData,
  isComponentPart,
} from '../../../common/forms';
import { getSxArray, styledProps } from '../../../common/styles';
import { Button, FlexBox, InfoTooltip, Link, Span, TextField } from '../../atoms';
import { ButtonProps } from '../../atoms/Button';
import { SelectOptionObject, TextFieldProps } from '../../atoms/TextField';
import { FormSwitch } from './FormSwitch';
import { useGetLabels } from './hooks/useGetLabels';

import styles from './styles.module.scss';

export function Form<TFormData extends object>({
  alert,
  apiRef,
  boxProps,
  defaultValues,
  disableAutoFocus: isAutoFocusDisabled,
  footer,
  footerLink,
  labels: propLabels,
  onChange,
  onSubmit,
  parts,
  readOnly: isReadOnlyForm,
  resourceType,
  submitBtnProps: propsSubmitBtnProps,
  ...formProps
}: FormProps<TFormData>) {
  const getLabels = useGetLabels();

  const fields = useMemo(() => getFields(parts), [parts]);

  const labels = useMemo(
    () => getLabels(formProps.name, fields, propLabels, resourceType),
    [fields, getLabels, formProps.name, propLabels, resourceType],
  );

  const initialFormData = useMemo(() => getInitialFormData<TFormData>(fields, defaultValues), [defaultValues, fields]);

  const [dirtyFields, setDirtyFields] = useState<Set<FieldName<TFormData>>>(new Set());
  const [updatedFormData, setFormData] = useState(initialFormData);

  const form = useRef<HTMLFormElement>(null);
  const isAutoFocusSet = useRef(false);
  const mutatedFieldNames = useRef(new Set<FieldName<TFormData>>());

  const formData = useMemo((): TFormData => {
    const fieldNames = Object.keys(fields) as (keyof TFormData)[];
    return pick({ ...initialFormData, ...updatedFormData }, fieldNames);
  }, [fields, initialFormData, updatedFormData]);

  const fieldErrors = useMemo(() => {
    const errors: Map<FieldName<TFormData>, string> = new Map();

    for (const [key, value] of Object.entries(formData)) {
      const name = key as FieldName<TFormData>;
      const field = fields[name];
      const error = getError(field, value, formData, labels.fields[name].errors);

      if (error) {
        errors.set(name, error);
      }
    }

    return errors;
  }, [fields, formData, labels.fields]);

  const isError = useMemo(() => fieldErrors.size > 0, [fieldErrors.size]);

  const alertLabel = useMemo(() => {
    if (!alert) {
      return;
    }

    const severity: FormAlertSeverity = alert?.props?.severity ?? 'info';
    return alert?.label ?? labels.form[`${severity}Alert`];
  }, [alert, labels.form]);

  const submitBtnProps = useMemo(() => {
    const { confirmProps, ...props } = propsSubmitBtnProps ?? {};
    const btnProps: ButtonProps = styledProps({ mt: 1 }, { disabled: isError, type: 'submit', ...props } as const);

    if (confirmProps) {
      btnProps.type = 'button';
      btnProps.confirmProps = {
        ...confirmProps,
        onClose: (reason) => {
          confirmProps.onClose?.(reason);

          if (reason === 'confirm') {
            form.current?.requestSubmit();
          }
        },
      };
    }

    return btnProps;
  }, [isError, propsSubmitBtnProps]);

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (isError || isReadOnlyForm || submitBtnProps?.disabled) {
        return;
      }

      onSubmit?.(formData, { ...event, formData: getFormSubmittedData<TFormData>(event) });
    },
    [formData, isError, isReadOnlyForm, onSubmit, submitBtnProps?.disabled],
  );

  const setDirty = useCallback(<TFieldName extends FieldName<TFormData>>(name: TFieldName) => {
    setDirtyFields((currentValue) => {
      if (currentValue.has(name)) {
        return currentValue;
      }

      const dirtyFields = new Set(currentValue);
      dirtyFields.add(name);
      return dirtyFields;
    });
  }, []);

  const setPristine = useCallback(<TFieldName extends FieldName<TFormData>>(name: TFieldName) => {
    setDirtyFields((currentValue) => {
      if (currentValue.has(name)) {
        const dirtyFields = new Set(currentValue);
        dirtyFields.delete(name);
        return dirtyFields;
      }

      return currentValue;
    });
  }, []);

  const handleBlur = useCallback(
    <TFieldName extends FieldName<TFormData>>(name: TFieldName) =>
      () => {
        if (!isReadOnlyForm) {
          setDirty(name);
        }
      },
    [isReadOnlyForm, setDirty],
  );

  const getErrors = useCallback(() => fieldErrors, [fieldErrors]);
  const getFormData = useCallback(() => formData, [formData]);

  const mutate = useCallback((cb: (currentValue: TFormData) => TFormData) => {
    setFormData((currentValue) => {
      const fieldNames = new Set<FieldName<TFormData>>();
      const formData = { ...cb(currentValue) };

      for (const [fieldName, value] of Object.entries(currentValue) as [FieldName<TFormData>, unknown][]) {
        if (formData[fieldName] !== value) {
          fieldNames.add(fieldName);
        }
      }

      mutatedFieldNames.current = new Set(fieldNames);

      return formData;
    });
  }, []);

  const mutateField = useCallback(
    <TFieldName extends FieldName<TFormData>>(name: TFieldName, value: TFormData[TFieldName]) => {
      setFormData((currentValue) => ({ ...currentValue, [name]: value }));
      mutatedFieldNames.current = new Set([name]);
    },
    [],
  );

  const refresh = useCallback(() => {
    mutate((currentValue) => ({ ...currentValue }));
  }, [mutate]);

  const handleSwitchChange = useCallback(
    <TFieldName extends FieldName<TFormData>>(name: TFieldName) =>
      (checked: boolean) => {
        if (isReadOnlyForm) {
          return;
        }

        mutateField(name, checked as TFormData[TFieldName]);
      },
    [isReadOnlyForm, mutateField],
  );

  const handleTextFieldChange = useCallback(
    <TFieldName extends FieldName<TFormData>>(name: TFieldName) =>
      ({ target }: ChangeEvent<HTMLInputElement>) => {
        if (isReadOnlyForm) {
          return;
        }

        const targetValue = target.value as TFormData[TFieldName];
        const formattedValue = fields[name]?.format?.(targetValue);
        const value = formattedValue ?? targetValue;

        mutateField(name, value);
      },
    [fields, isReadOnlyForm, mutateField],
  );

  const renderFormField = useCallback(
    <TFieldName extends FieldName<TFormData>>({
      fieldName,
      isHidden,
      isOptionEqualToValue,
      props: textOrSwitchFieldProps,
      tooltip,
    }: FieldProps<TFormData, TFieldName>) => {
      const {
        FormHelperTextProps,
        helperText,
        InputProps,
        label: propsLabel,
        multiple,
        options,
        select,
        sx,
        type,
        ...props
      } = textOrSwitchFieldProps ?? {};

      const errorText = dirtyFields.has(fieldName) && fieldErrors.get(fieldName);
      const helperTextContent = <Span>{errorText || helperText}</Span>;
      const label = propsLabel ?? labels.fields[fieldName].label;
      const value = formData[fieldName];

      const tooltipElement = tooltip ? (
        <InfoTooltip iconProps={{ sx: { fontSize: 18 } }} placement="left" title={labels.fields[fieldName].tooltip} />
      ) : null;

      const fieldProps = {
        error: dirtyFields.has(fieldName) && fieldErrors.has(fieldName),
        FormHelperTextProps: styledProps(
          { alignItems: 'flex-start', justifyContent: 'space-between', mr: 0 },
          FormHelperTextProps,
        ),
        name: fieldName,
        onBlur: handleBlur(fieldName),
        sx: isHidden ? [...getSxArray(sx), { visibility: 'hidden' }] : sx,
        ...props,
      } as FormSwitchProps | TextFieldProps;

      if (!isReadOnlyForm && !InputProps?.readOnly && !isAutoFocusDisabled && !isAutoFocusSet.current) {
        fieldProps.autoFocus = true;
        isAutoFocusSet.current = true;
      }

      if (type === 'switch') {
        const switchFieldProps = {
          ...fieldProps,
          checked: value,
          helperText: helperTextContent,
          label: (
            <>
              <Span>{label}</Span>
              {tooltipElement}
            </>
          ),
          onChange: handleSwitchChange(fieldName),
          readOnly: isReadOnlyForm,
        } as FormSwitchProps;

        return <FormSwitch key={fieldName} {...switchFieldProps} />;
      }

      const textFieldProps = {
        ...fieldProps,
        helperText: (
          <>
            {helperTextContent}
            {tooltipElement}
          </>
        ),
        InputProps,
        label,
        multiple,
        onChange: handleTextFieldChange(fieldName),
        options,
        select,
        type,
        value,
      } as TextFieldProps;

      if (isReadOnlyForm) {
        textFieldProps.InputProps ??= {};
        textFieldProps.InputProps.readOnly = true;
      }

      if (select && options && multiple && select) {
        if (isOptionEqualToValue && Array.isArray(textFieldProps.value)) {
          textFieldProps.value = textFieldProps.value.flatMap((value) => {
            const option = options.find(
              (option) => typeof option !== 'function' && isOptionEqualToValue(option.value, value),
            ) as SelectOptionObject | undefined;

            return option?.value ?? [];
          });
        }

        textFieldProps.SelectProps = {
          renderValue: (items) => {
            if (Array.isArray(items)) {
              return (
                <FlexBox sx={{ gap: 0.5, flexWrap: 'wrap' }}>
                  {items.map((item, index) => {
                    const option = options.find((option) => typeof option !== 'function' && option.value === item) as
                      | SelectOptionObject
                      | undefined;

                    if (option) {
                      const { label, renderValue } = option;

                      return (
                        <Chip
                          key={index}
                          label={renderValue ?? label}
                          onMouseDown={(event) => {
                            event?.stopPropagation();
                          }}
                          {...(!textFieldProps.InputProps?.readOnly && {
                            onDelete: () => {
                              setFormData((currentValue) => {
                                const fieldValue = [...(currentValue[fieldName] as unknown[])];
                                fieldValue.splice(index, 1);
                                return { ...currentValue, [fieldName]: fieldValue };
                              });
                            },
                          })}
                        />
                      );
                    }
                  })}
                </FlexBox>
              );
            }
          },
        };
      }

      return <TextField key={fieldName} {...textFieldProps} />;
    },
    [
      dirtyFields,
      fieldErrors,
      formData,
      handleBlur,
      handleSwitchChange,
      handleTextFieldChange,
      isAutoFocusDisabled,
      isReadOnlyForm,
      labels.fields,
    ],
  );

  const renderSinglePart = useCallback(
    <TFieldName extends FieldName<TFormData>>(part: FormPart<TFormData, TFieldName>, key: number) => {
      if (isComponentPart(part)) {
        const Part = part;
        return <Part key={key} />;
      }

      return renderFormField(part as FieldProps<TFormData, TFieldName>);
    },
    [renderFormField],
  );

  const renderPartGroup = useCallback(
    (parts: FormPart<TFormData, FieldName<TFormData>>[], key: number) => {
      const partGroup = parts.map(renderSinglePart);
      const isSwitchGroup = parts.every((part) => 'props' in part && part.props?.type === 'switch');

      if (isSwitchGroup) {
        return (
          <FormGroup row key={key}>
            {partGroup}
          </FormGroup>
        );
      }

      return (
        <FlexBox key={key} sx={{ gap: '0.8rem', position: 'relative', '& > *': { flex: '1 0' } }}>
          {partGroup}
        </FlexBox>
      );
    },
    [renderSinglePart],
  );

  const renderPart = useCallback(
    (part: FormPart<TFormData, FieldName<TFormData>>, index: number) =>
      Array.isArray(part) ? renderPartGroup(part, index) : renderSinglePart(part, index),
    [renderPartGroup, renderSinglePart],
  );

  useEffect(() => {
    if (apiRef) {
      apiRef.current = { getErrors, getFormData, mutate, refresh, setDirty, setDirtyFields, setPristine };
    }
  }, [apiRef, getErrors, getFormData, mutate, refresh, setDirty, setDirtyFields, setPristine]);

  useEffect(() => {
    if (mutatedFieldNames.current?.size) {
      const fieldNames = mutatedFieldNames.current;
      mutatedFieldNames.current = new Set();

      for (const fieldName of fieldNames) {
        fields[fieldName]?.onChange?.(formData[fieldName], formData);
      }
    }
  }, [fields, formData]);

  useEffect(() => {
    onChange?.(formData);
  }, [formData, onChange]);

  return (
    <form className={styles['form']} onSubmit={handleSubmit} ref={form} {...formProps}>
      <FlexBox column {...styledProps({ width: '100%', height: '100%', padding: 3 }, boxProps)}>
        {parts.map(renderPart)}

        {!isReadOnlyForm && <Button {...submitBtnProps}>{labels.form.submitBtn}</Button>}

        {alertLabel && (
          <Alert {...styledProps([{ whiteSpace: 'pre-line' }, !isReadOnlyForm && { mt: 2 }], alert?.props)}>
            {alertLabel}
          </Alert>
        )}

        {footer && <Box sx={{ mt: 2 }}>{footer}</Box>}

        {footerLink && (
          <Link {...styledProps({ mt: 4, textAlign: 'center' }, footerLink.props)}>
            {footerLink.label ?? labels.form.footerLink}
          </Link>
        )}
      </FlexBox>
    </form>
  );
}
