import { AlertProps, FormControlProps, FormHelperTextProps, TextFieldVariants } from '@mui/material';
import {
  AllHTMLAttributes,
  DetailedHTMLProps,
  FormEvent,
  FormHTMLAttributes,
  MutableRefObject,
  ReactNode,
} from 'react';

import { ButtonProps } from '../../components/atoms/Button';
import { CheckboxProps } from '../../components/atoms/Checkbox';
import { FlexBoxProps } from '../../components/atoms/FlexBox';
import { LinkProps } from '../../components/atoms/Link';
import { TextFieldProps } from '../../components/atoms/TextField';
import { ResourceType } from '../api/resources';

export type FieldName<TFormData> = string & keyof TFormData;

export type FieldProps<TFormData, TFieldName extends FieldName<TFormData> = FieldName<TFormData>> = {
  format?: (value: TFormData[TFieldName]) => TFormData[TFieldName];
  getError?: (value: TFormData[TFieldName], formData: TFormData) => string | undefined;
  fieldName: TFieldName;
  isHidden?: boolean;
  isOptionEqualToValue?: (a: unknown, b: unknown) => boolean;
  onChange?: (value: TFormData[TFieldName], formData: TFormData) => void;
  props?: FormTextFieldProps<TFormData[TFieldName]> | ({ type: 'switch' } & FormSwitchProps);
  tooltip?: boolean;
};

export type Fields<TFormData> = {
  [TFieldName in FieldName<TFormData>]: FieldProps<TFormData, TFieldName>;
};

export type FieldValueType = 'array' | 'boolean' | 'string';

export type FormSwitchProps = {
  error?: boolean;
  FormControlProps?: Omit<FormControlProps, 'error'>;
  FormHelperTextProps?: FormHelperTextProps;
  helperText: ReactNode;
  inputProps?: never;
  InputProps?: never;
  multiple?: never;
  options?: never;
  select?: never;
} & CheckboxProps;

export type FormTextFieldProps<TValue> = {
  inputProps?: Omit<AllHTMLAttributes<HTMLInputElement>, 'max' | 'maxLength' | 'min' | 'pattern' | 'step'> & {
    max?: number;
    maxLength?: number;
    min?: number;
    pattern?: RegExp;
    step?: number;
  };
} & Omit<TextFieldProps<TextFieldVariants, TValue>, 'error' | 'inputProps' | 'onBlur' | 'onChange' | 'value'>;

export type FormAlertProp = {
  label?: string;
  props?: { severity?: FormAlertSeverity } & AlertProps;
};
export type FormAlertSeverity = 'error' | 'info' | 'success' | 'warning';

export type FormApi<TFormData> = {
  getErrors: () => Readonly<Map<FieldName<TFormData>, string>>;
  getFormData: () => Readonly<TFormData>;
  mutate: (cb: (currentValue: TFormData) => TFormData) => void;
  refresh: () => void;
  setDirty: (name: FieldName<TFormData>) => void;
  setDirtyFields: (cb: (currentValue: Set<FieldName<TFormData>>) => Set<FieldName<TFormData>>) => void;
  setPristine: (name: FieldName<TFormData>) => void;
};

export type FormFooterLink = { label?: string; props: LinkProps };

export const FORM_LABELS = [
  'errorAlert',
  'footerLink',
  'infoAlert',
  'submitBtn',
  'successAlert',
  'warningAlert',
] as const;
export type FormLabel = (typeof FORM_LABELS)[number];

export const FORM_FIELD_ERROR_KEYS = [
  'empty',
  'invalid',
  'invalidEmail',
  'invalidNumber',
  'invalidPhoneNumber',
  'maxNumber',
  'minNumber',
] as const;
export type FormFieldErrorKey = (typeof FORM_FIELD_ERROR_KEYS)[number];

export type FormLabels = { [k in FormLabel]: string };
export type FormFieldErrorLabels = { [k in FormFieldErrorKey]: (params?: Record<string, unknown>) => string };
export type FormFieldLabels = { errors: FormFieldErrorLabels; label: string };
export type FormLabelOverrides<TFormData> = {
  fields?: Partial<
    Record<FieldName<TFormData>, { errors?: Partial<FormFieldErrorLabels>; label?: string; tooltip?: string }>
  >;
  form?: Partial<FormLabels>;
};
export type Labels<TFormData> = {
  fields: { [k in FieldName<TFormData>]: { errors: FormFieldErrorLabels; label: string; tooltip: string } };
  form: FormLabels;
};

type FormComponentPart = () => ReactNode;

export type FormPart<TFormData, TFieldName extends FieldName<TFormData>> =
  | FieldProps<TFormData, TFieldName>
  | FormComponentPart
  | (FormComponentPart | FieldProps<TFormData, TFieldName>)[];
export type FormParts<TFormData> = FormPart<TFormData, FieldName<TFormData>>[];

export type FormOverrideProps<TFormData> = Omit<FormProps<TFormData>, 'name' | 'parts'>;

export type FormProps<TFormData> = {
  alert?: FormAlertProp;
  apiRef?: MutableRefObject<FormApi<TFormData> | null>;
  boxProps?: FlexBoxProps;
  defaultValues?: Partial<TFormData>;
  disableAutoFocus?: boolean;
  footer?: ReactNode;
  footerLink?: FormFooterLink;
  labels?: FormLabelOverrides<TFormData>;
  onChange?: (formData: TFormData) => void;
  onSubmit?: (formData: TFormData, event: FormSubmitEvent<TFormData>) => void;
  parts: FormParts<TFormData>;
  readOnly?: boolean;
  resourceType?: FormResourceType;
  submitBtnProps?: ButtonProps;
} & Omit<DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>, 'onChange' | 'onSubmit'> & {
    name: string;
  };

export type FormResourceType = 'schedule' | ResourceType;

export type FormSubmitEvent<TFormData> = FormEvent<HTMLFormElement> & { formData: TFormData };
